import React from "react";
import LandingPage from "./landing-page";

export default function Home() {
  return (
    <>
      <LandingPage />
    </>
  )
}
